// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mission_mission__ht0j1 .Mission_box_info__riOZ7 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.Mission_mission__ht0j1 .Mission_box_info__riOZ7 h1 {
  margin: 0;
  margin-top: 60px;
}
.Mission_mission__ht0j1 .Mission_box_info__riOZ7 p {
  max-width: 700px;
}
.Mission_mission__ht0j1 .Mission_box_info__riOZ7 .Mission_box_foto__SVVu2 {
  box-sizing: border-box;
  padding: 50px 30px 0 30px;
}
.Mission_mission__ht0j1 .Mission_box_info__riOZ7 .Mission_box_foto__SVVu2 img {
  box-sizing: border-box;
  max-width: 450px;
  width: 100%;
}
@media (max-width: 740px) {
  .Mission_mission__ht0j1 .Mission_box_info__riOZ7 {
    flex-direction: column;
    justify-content: center;
  }
  .Mission_mission__ht0j1 .Mission_box_info__riOZ7 .Mission_box_foto__SVVu2 {
    width: 100%;
    padding: 10px 20px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Mission_mission__ht0j1 .Mission_box_info__riOZ7 .Mission_box_foto__SVVu2 img {
    max-width: 400px;
    padding-right: 30px;
  }
}
@media (max-width: 740px) and (max-width: 520px) {
  .Mission_mission__ht0j1 .Mission_box_info__riOZ7 .Mission_box_foto__SVVu2 img {
    max-width: 350px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Mission/Mission.module.scss"],"names":[],"mappings":"AAKG;EACG,aAAA;EACA,uBAAA;EACA,8BAAA;AAJN;AAMM;EACG,SAAA;EACA,gBAAA;AAJT;AAOM;EACG,gBAAA;AALT;AAQM;EACG,sBAAA;EACA,yBAAA;AANT;AAOS;EACG,sBAAA;EACA,gBAAA;EACA,WAAA;AALZ;AASM;EAxBH;IAyBM,sBAAA;IACA,uBAAA;EANP;EAQO;IACG,WAAA;IACA,yBAAA;IACA,aAAA;IACA,uBAAA;IACA,mBAAA;EANV;EAQU;IACG,gBAAA;IACA,mBAAA;EANb;AACF;AAOe;EAJH;IAKM,gBAAA;EAJhB;AACF","sourcesContent":["\r\n\r\n.mission {\r\n\r\n\r\n   .box_info{\r\n      display: flex;\r\n      align-items: flex-start;\r\n      justify-content: space-between;\r\n\r\n      h1{\r\n         margin: 0;\r\n         margin-top: 60px;\r\n      }\r\n\r\n      p{\r\n         max-width: 700px;\r\n      }\r\n\r\n      .box_foto{\r\n         box-sizing: border-box;\r\n         padding: 50px 30px 0 30px;\r\n         img {\r\n            box-sizing: border-box;\r\n            max-width: 450px;\r\n            width: 100%;\r\n         }\r\n      }\r\n\r\n      @media (max-width: 740px) {\r\n         flex-direction: column;\r\n         justify-content: center;\r\n\r\n         .box_foto{\r\n            width: 100%;\r\n            padding: 10px 20px 0 20px;\r\n            display: flex;\r\n            justify-content: center;\r\n            align-items: center;\r\n            \r\n            img{\r\n               max-width: 400px;\r\n               padding-right: 30px;\r\n\r\n               @media (max-width: 520px) {\r\n                  max-width: 350px;\r\n               }\r\n            }\r\n         }\r\n      }\r\n   }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mission": `Mission_mission__ht0j1`,
	"box_info": `Mission_box_info__riOZ7`,
	"box_foto": `Mission_box_foto__SVVu2`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  font-size: 22px;
}
@media (max-width: 1420px) {
  html {
    font-size: 20px;
  }
}
@media (max-width: 1180px) {
  html {
    font-size: 19px;
  }
}
@media (max-width: 1080px) {
  html {
    font-size: 18px;
  }
}
@media (max-width: 1080px) {
  html {
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  html {
    font-size: 15px;
  }
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6F6F6;
}

code {
  font-family: "Open Sans", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EAHF;IAIM,eAAA;EAEJ;AACF;AAAE;EAPF;IAQI,eAAA;EAGF;AACF;AADE;EAXF;IAYI,eAAA;EAIF;AACF;AAFE;EAfF;IAgBI,eAAA;EAKF;AACF;AAHE;EAnBF;IAoBI,eAAA;EAMF;AACF;;AAHA;EACE,SAAA;EACA,oCAAA;EACA,mCAAA;EACA,kCAAA;EACA,yBAAA;AAMF;;AAHA;EACE,oCAAA;AAMF","sourcesContent":["html {\n  font-size: 22px;\n\n  @media (max-width: 1420px) {\n      font-size: 20px;\n  }\n\n  @media (max-width: 1180px) {\n    font-size: 19px;\n  }\n\n  @media (max-width: 1080px) {\n    font-size: 18px;\n  }\n\n  @media (max-width: 1080px) {\n    font-size: 16px;\n  }\n\n  @media (max-width: 480px) {\n    font-size: 15px;\n  }\n}\n\nbody {\n  margin: 0;\n  font-family: 'Open Sans', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #F6F6F6;\n}\n\ncode {\n  font-family: 'Open Sans', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

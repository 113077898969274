// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonApp_btn__DvR2r {
  padding: 0.1rem 0.8333rem 0.1rem 1.25rem;
  font-size: 1rem;
  font-family: Open Sans;
  outline: none;
  border-radius: 50px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  border: 3px solid transparent;
}
.ButtonApp_btn__DvR2r i {
  font-size: 1.8em;
  transition: 0.3s;
}

.ButtonApp_btn__DvR2r.ButtonApp_red__nkVD7 {
  color: var(--white);
  background-color: var(--red);
  transition: 0.2s;
}

.ButtonApp_btn__DvR2r:hover {
  background-color: var(--red-hover);
}
.ButtonApp_btn__DvR2r:hover i {
  transform: translateX(4px);
}

.ButtonApp_btn__DvR2r.ButtonApp_white__BJ\\+gC {
  color: var(--red);
  background-color: var(--white);
  border: 3px solid var(--red);
  transition: 0.2s;
}

.ButtonApp_btn__DvR2r.ButtonApp_white__BJ\\+gC:hover {
  color: var(--red-hover);
}

.ButtonApp_btn__DvR2r.ButtonApp_link__vsqIO {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/ButtonApp/ButtonApp.module.scss"],"names":[],"mappings":"AACA;EACG,wCAAA;EACA,eAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,oBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,6BAAA;AAAH;AAEG;EACG,gBAAA;EACA,gBAAA;AAAN;;AAIA;EACG,mBAAA;EACA,4BAAA;EACA,gBAAA;AADH;;AAIA;EACG,kCAAA;AADH;AAEG;EACG,0BAAA;AAAN;;AAIA;EACG,iBAAA;EACA,8BAAA;EACA,4BAAA;EACA,gBAAA;AADH;;AAIA;EACG,uBAAA;AADH;;AAIA;EACG,qBAAA;AADH","sourcesContent":["\r\n.btn{\r\n   padding: 0.1rem 0.8333rem 0.1rem 1.25rem;\r\n   font-size: 1rem;\r\n   font-family: Open Sans;\r\n   outline: none;\r\n   border-radius: 50px;\r\n   font-weight: 700;\r\n   display: inline-flex;;\r\n   align-items: center;\r\n   gap: 0.5rem;\r\n   cursor: pointer;\r\n   border: 3px solid transparent;\r\n\r\n   i{\r\n      font-size: 1.8em;\r\n      transition: 0.3s;\r\n   }\r\n}\r\n\r\n.btn.red{\r\n   color: var(--white);\r\n   background-color: var(--red);\r\n   transition: 0.2s;\r\n}\r\n\r\n.btn:hover{\r\n   background-color: var(--red-hover);\r\n   i{\r\n      transform: translateX(4px);\r\n   }\r\n}\r\n\r\n.btn.white{\r\n   color: var(--red);\r\n   background-color: var(--white);\r\n   border: 3px solid var(--red);\r\n   transition: 0.2s;\r\n}\r\n\r\n.btn.white:hover{\r\n   color: var(--red-hover);\r\n}\r\n\r\n.btn.link{\r\n   text-decoration: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `ButtonApp_btn__DvR2r`,
	"red": `ButtonApp_red__nkVD7`,
	"white": `ButtonApp_white__BJ+gC`,
	"link": `ButtonApp_link__vsqIO`
};
export default ___CSS_LOADER_EXPORT___;

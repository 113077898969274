// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Belarus_belarus__UwXrX {
  padding-block: 75px 60px;
}
.Belarus_belarus__UwXrX p {
  max-width: 1000px;
}
.Belarus_belarus__UwXrX .Belarus_box_img__\\+I1L6 {
  display: flex;
  justify-content: flex-end;
}
.Belarus_belarus__UwXrX .Belarus_box_img__\\+I1L6 img {
  max-width: 40%;
  margin: -30px -100px 0 0;
}
@media (max-width: 960px) {
  .Belarus_belarus__UwXrX .Belarus_box_img__\\+I1L6 img {
    max-width: 60%;
    margin: -20px -100px 0 0;
  }
}
@media (max-width: 740px) {
  .Belarus_belarus__UwXrX .Belarus_box_img__\\+I1L6 img {
    max-width: 80%;
    margin: -20px -100px 0 0;
  }
}
@media (max-width: 480px) {
  .Belarus_belarus__UwXrX .Belarus_box_img__\\+I1L6 img {
    max-width: 100%;
    margin: -10px -70px 0 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Belarus/Belarus.module.scss"],"names":[],"mappings":"AAEA;EACG,wBAAA;AADH;AAGG;EACG,iBAAA;AADN;AAIG;EACG,aAAA;EACA,yBAAA;AAFN;AAGM;EACG,cAAA;EACA,wBAAA;AADT;AAGS;EAJH;IAKM,cAAA;IACA,wBAAA;EAAV;AACF;AAES;EATH;IAUM,cAAA;IACA,wBAAA;EACV;AACF;AACS;EAdH;IAeM,eAAA;IACA,uBAAA;EAEV;AACF","sourcesContent":["\r\n\r\n.belarus{\r\n   padding-block: 75px 60px;\r\n   \r\n   p{\r\n      max-width: 1000px;\r\n   }\r\n\r\n   .box_img{\r\n      display: flex;\r\n      justify-content: flex-end;\r\n      img{\r\n         max-width: 40%;\r\n         margin: -30px -100px 0 0;\r\n\r\n         @media (max-width: 960px) {\r\n            max-width: 60%;\r\n            margin: -20px -100px 0 0;\r\n         }\r\n\r\n         @media (max-width: 740px) {\r\n            max-width: 80%;\r\n            margin: -20px -100px 0 0;\r\n         }\r\n\r\n         @media (max-width: 480px) {\r\n            max-width: 100%;\r\n            margin: -10px -70px 0 0;\r\n         }\r\n      }\r\n   }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"belarus": `Belarus_belarus__UwXrX`,
	"box_img": `Belarus_box_img__+I1L6`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Translation_translation__YkDhv {
  display: flex;
  cursor: pointer;
  gap: 5px;
}
.Translation_translation__YkDhv span {
  transition: 0.2s;
}
.Translation_translation__YkDhv span.Translation_active__1Gl20 {
  text-transform: uppercase;
  color: var(--red);
}
.Translation_translation__YkDhv span:hover {
  color: var(--red) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Translation/Translation.module.scss"],"names":[],"mappings":"AACA;EACG,aAAA;EACA,eAAA;EACA,QAAA;AAAH;AACG;EACG,gBAAA;AACN;AACG;EACG,yBAAA;EACA,iBAAA;AACN;AAEG;EACG,4BAAA;AAAN","sourcesContent":["\n.translation{\n   display: flex;\n   cursor: pointer;\n   gap: 5px;\n   span{\n      transition: 0.2s;\n   }\n   span.active{\n      text-transform: uppercase;\n      color: var(--red);\n   }\n\n   span:hover{\n      color: var(--red) !important;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"translation": `Translation_translation__YkDhv`,
	"active": `Translation_active__1Gl20`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.History_history__5XMYg {
  margin-top: 65px;
}
.History_history__5XMYg h1 {
  margin: 0;
}
.History_history__5XMYg .History_box_info__dHF6k {
  display: flex;
  justify-content: space-between;
}
.History_history__5XMYg .History_box_info__dHF6k p {
  max-width: 850px;
}
.History_history__5XMYg .History_box_info__dHF6k .History_box_foto__s7Ich {
  padding: 40px 20px 0 20px;
}
.History_history__5XMYg .History_box_info__dHF6k .History_box_foto__s7Ich img {
  max-width: 500px;
  width: 100%;
}
@media (max-width: 740px) {
  .History_history__5XMYg .History_box_info__dHF6k {
    flex-direction: column;
    justify-content: center;
  }
  .History_history__5XMYg .History_box_info__dHF6k .History_box_foto__s7Ich {
    padding: 10px 20px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .History_history__5XMYg .History_box_info__dHF6k .History_box_foto__s7Ich img {
    max-width: 400px;
  }
}
@media (max-width: 740px) and (max-width: 520px) {
  .History_history__5XMYg .History_box_info__dHF6k .History_box_foto__s7Ich {
    max-width: 350px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/History/History.module.scss"],"names":[],"mappings":"AAEA;EACG,gBAAA;AADH;AAGG;EACG,SAAA;AADN;AAIG;EACG,aAAA;EACA,8BAAA;AAFN;AAIM;EACG,gBAAA;AAFT;AAKM;EACG,yBAAA;AAHT;AAIS;EACG,gBAAA;EACA,WAAA;AAFZ;AAMM;EAhBH;IAiBM,sBAAA;IACA,uBAAA;EAHP;EAKO;IACG,yBAAA;IACA,aAAA;IACA,uBAAA;IACA,mBAAA;EAHV;EAKU;IACG,gBAAA;EAHb;AACF;AAKY;EAVH;IAWM,gBAAA;EAFb;AACF","sourcesContent":["\r\n\r\n.history{\r\n   margin-top: 65px;\r\n   \r\n   h1{\r\n      margin: 0;\r\n   }\r\n\r\n   .box_info{\r\n      display: flex;\r\n      justify-content: space-between;\r\n\r\n      p{\r\n         max-width: 850px;\r\n      }\r\n\r\n      .box_foto{\r\n         padding: 40px 20px 0 20px;\r\n         img {\r\n            max-width: 500px;\r\n            width: 100%;\r\n         }\r\n      }\r\n\r\n      @media (max-width: 740px) {\r\n         flex-direction: column;\r\n         justify-content: center;\r\n\r\n         .box_foto{\r\n            padding: 10px 20px 0 20px;\r\n            display: flex;\r\n            justify-content: center;\r\n            align-items: center;\r\n            \r\n            img{\r\n               max-width: 400px\r\n            }\r\n\r\n            @media (max-width: 520px) {\r\n               max-width: 350px;\r\n            }\r\n         }\r\n      }\r\n   }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"history": `History_history__5XMYg`,
	"box_info": `History_box_info__dHF6k`,
	"box_foto": `History_box_foto__s7Ich`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconInst_link__Ib-xd {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: scale(1.4);
  cursor: pointer;
}
.IconInst_link__Ib-xd svg {
  display: inline-block;
  fill: var(--red);
  transform: scale(1.2);
  transition: 0.3s;
}

.IconInst_link__Ib-xd:hover svg {
  fill: var(--red-hover);
  transform: scale(1.3);
}`, "",{"version":3,"sources":["webpack://./src/components/UI/IconInst/IconInst.module.scss"],"names":[],"mappings":"AAEA;EACG,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,qBAAA;EAEA,eAAA;AAFH;AAIG;EACG,qBAAA;EACA,gBAAA;EACA,qBAAA;EACA,gBAAA;AAFN;;AAOG;EACG,sBAAA;EACA,qBAAA;AAJN","sourcesContent":["\r\n\r\n.link {\r\n   background-color: #fff;\r\n   display: flex;\r\n   align-items: center;\r\n   justify-content: center;\r\n   border-radius: 50%;\r\n   transform: scale(1.4);\r\n\r\n   cursor: pointer;\r\n\r\n   svg {\r\n      display: inline-block;\r\n      fill: var(--red);\r\n      transform: scale(1.2);\r\n      transition: 0.3s;\r\n   }\r\n}\r\n\r\n.link:hover {\r\n   svg {\r\n      fill: var(--red-hover);\r\n      transform: scale(1.3);\r\n   }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `IconInst_link__Ib-xd`
};
export default ___CSS_LOADER_EXPORT___;

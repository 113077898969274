// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Team_team__0eXSr {
  margin-bottom: 90px;
}
@media (max-width: 760px) {
  .Team_team__0eXSr h1 {
    max-width: 420px;
  }
}
.Team_team__0eXSr .Team_cards__RxMK7 {
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}
@media (max-width: 840px) {
  .Team_team__0eXSr .Team_cards__RxMK7 {
    grid-template-columns: 1fr;
    gap: 70px;
  }
}
@media (max-width: 540px) {
  .Team_team__0eXSr .Team_cards__RxMK7 {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Team/Team.module.scss"],"names":[],"mappings":"AAAA;EAEG,mBAAA;AAAH;AAEG;EACG;IACG,gBAAA;EAAP;AACF;AAGG;EACG,gBAAA;EAGA,aAAA;EACA,eAAA;EACA,SAAA;EACA,uBAAA;AAHN;AAKM;EATH;IAUM,0BAAA;IACA,SAAA;EAFP;AACF;AAIM;EAdH;IAeM,0BAAA;IACA,SAAA;EADP;AACF","sourcesContent":[".team {\r\n\r\n   margin-bottom: 90px;\r\n\r\n   @media (max-width: 760px) {\r\n      h1 {\r\n         max-width: 420px;\r\n      }\r\n   }\r\n\r\n   .cards {\r\n      margin-top: 70px;\r\n      // display: grid;\r\n      // grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));\r\n      display: flex;\r\n      flex-wrap: wrap;\r\n      gap: 30px;\r\n      justify-content: center;\r\n\r\n      @media (max-width: 840px) {\r\n         grid-template-columns: 1fr;\r\n         gap: 70px;\r\n      }\r\n\r\n      @media (max-width: 540px) {\r\n         grid-template-columns: 1fr;\r\n         gap: 40px;\r\n      }\r\n   }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"team": `Team_team__0eXSr`,
	"cards": `Team_cards__RxMK7`
};
export default ___CSS_LOADER_EXPORT___;

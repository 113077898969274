// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./beer-money.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
@font-face {
  font-family: "Beer money";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  /* Дополнительные свойства шрифта, если требуется */
  font-weight: normal;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACG,yBAAA;EACA,+DAAA;EACA,mDAAA;EACA,mBAAA;EACA,kBAAA;AACH","sourcesContent":["\r\n@font-face {\r\n   font-family: 'Beer money';\r\n   src: url('./beer-money.ttf') format('truetype');\r\n   /* Дополнительные свойства шрифта, если требуется */\r\n   font-weight: normal;\r\n   font-style: normal;\r\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
